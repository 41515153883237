// CSS Imports
import "@fontsource-variable/mulish";
import '/src/css/style.css';

// NPM Imports
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';

import init from './init';

(ready => {
  if (document.readyState !== 'loading') {
    ready();
  } else {
    document.addEventListener('DOMContentLoaded', ready);
  }
})(() => {
  init(document);
  window.Alpine = Alpine;
  Alpine.plugin(persist);
  Alpine.plugin(focus);
  Alpine.start();
});
